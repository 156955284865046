import pkg from '../package.json';
import { Colors, Fonts, Images, Pagination, Productos } from './constants';

const config = {
  env: 'dev', // process.env.REACT_APP_APP_ENV || 'local',
  apiURL: 'https://api.sirio.desarrollo.nuup.org', // process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000',
  googleApiKey: 'AIzaSyAgkOHYMjZAnpinxGyvxPz-w_pQsxU5E5k', // process.env.REACT_APP_API_KEY_GOOGLE_MAPS || 'AIzaSyAV4DLTDLTJRxSYJcquS6aqDqRjIqkmtHM',
};

const env = {
  appEnv: config.env,
  appName: 'Sirio',
  appVersion: pkg.version,
  assetURL: config.apiURL,
  apiURL: config.apiURL + '/api',
  googleApiKey: config.googleApiKey,

  colors: Colors,
  fonts: Fonts,
  images: Images,
  pagination: Pagination,
  productos: Productos,

  paddings: {
    button: {
      paddingTop: 4,
      paddingLeft: 18,
      paddingBottom: 4,
      paddingRight: 18,
    },
  },
};

if (config.env !== 'prod') {
  console.log('environment.js: -> ', env);
}

export default env;
